import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../service/api.ts';
import { parseJSON } from '../util';

import { useGlobalStoreUser } from '../context/global';
import AlertDialog from '../modal/AlertDialog';

async function getUserCredit() {
  const response = await api.getUserCredit();
  console.log('CREDIT', response?.data);
  return response?.data;
}

const UserCredit = () => {
  const [globalStoreUser, globalDispatchUser] = useGlobalStoreUser();
  const { t } = useTranslation('home');
  const [credit, setCredit] = useState({ limit:0, debt:0, days: 0 });

  useEffect(async () => {
    const resCredit = await getUserCredit();
    if (resCredit?.status !== 0) return;

    const dataCredit = parseJSON(resCredit?.dane);
    const _credit = { 
      limit: parseFloat(dataCredit.limit), 
      debt: parseFloat(dataCredit?.debt || 0), 
      days: parseInt(dataCredit?.days || 0, 10),
    };

    setCredit(_credit);
    globalDispatchUser.setCredit(_credit);
  }, [globalStoreUser]);

  useEffect(() => {
    if (!!globalStoreUser?.debt_popup_disable) return;
    if (credit.debt >= -1 && credit.days <= 0) return;

    const template = [];

    if (credit.debt < -1) {
      template.push(<p key="user_credit_debt">{t('Został przekroczony limit kredytu')}: <b className='text-red-600'>{credit.debt}&nbsp;zł</b></p>);
    }

    if (credit.days > 0) {
      template.push(<p key="user_credit_days">{t('Został przekroczony termin płatności')}: <b className='text-red-600'>{credit.days}&nbsp;{credit.days > 1 ? t("dni") : t("dzień")}</b></p>);
    }

    AlertDialog({
      key: "userCredit",
      title: "Informacja",
      message: template,
      color: "white",
    });
  }, [credit]);


  return (
    <>
      {credit.limit > 0 && (
        <div className="hidden lg:block">
          <span className="text-gray-600">{t('Limit kredytu')}:</span>
          &nbsp;
          <span className="text-green-600">
            <span data-cy="credit-limit">{credit.limit || 0}</span>
            &nbsp;zł
          </span>
        </div>
      )}
      {credit.debt < -1 && (
        <div className="hidden lg:block">
          <span className="text-gray-600">{t('Saldo')}:</span>
          &nbsp;
          <span className="text-red-600">
            <span data-cy="debt-limit">{credit.debt || 0}</span>
            &nbsp;zł
          </span>
        </div>
      )}
      {credit.days > 0 && (
        <div className="hidden lg:block">
          <span className="text-gray-600" data-title="Przekroczony termin płatności">{t('Po terminie')}:</span>
          &nbsp;
          <span className="text-red-600">
            <span data-cy="days-limit">{credit.days || 0}</span>
            &nbsp;{credit.days > 1 ? t("dni") : t("dzień")}
          </span>
        </div>
      )}
    </>
  );
};

export default UserCredit;
