import { useEffect, useCallback } from "react";

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

function useEscapeKey(handleClose) {
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC && typeof handleClose == "function") {
      setTimeout(() => handleClose(), 10);
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
}

export default useEscapeKey;
