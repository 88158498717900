import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { toBoolean } from './util';

const gaSettings = { enable: false };

export const gaEvent = (hitType = 'notfound', page = {}) => {
  if (gaSettings.enable !== true || window?.gtag === undefined) return;

  console.log('gaEvent', hitType, page);
  window.gtag('event', hitType /* etc. page_view */, page);
};

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    gaEvent('page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

// eslint-disable-next-line react/prop-types
const GoogleAnalytics = ({ apiKey = '' }) => {
  if (!toBoolean(apiKey)) return '';

  usePageTracking();

  useEffect(() => {
    console.log('GA4 init');
    gaSettings.enable = true;

    return () => {
      gaSettings.enable = false;
    };
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${apiKey}`} />
        <script>
          {`
           window.dataLayer = window.dataLayer || []; 
              function gtag() {
                dataLayer.push(arguments);
              } 
              gtag('js', new Date()); 
              gtag('config', '${apiKey}', { send_page_view: false });
          `}
        </script>
      </Helmet>
    </HelmetProvider>
  );
};

export default GoogleAnalytics;
