// context/global.js

import { default as storage } from '../util/storage';
import { createStore, createHook } from 'react-sweet-state';
import { applyColors, getUserObj } from '../util';
import "./i18n";

const globalLang = storage.get('global-lang', 'pl');
const globalLight = storage.get('global-light', 0);
const globalSearch = storage.getObject('global-search', '');
const globalSearchType = storage.getObject('global-search-type', 0);
const globalFilter = storage.getObject('global-filter', {});
const globalUser = storage.getObject('global-user', getUserObj());
const globalCategory = storage.getObject('global-category', '');

applyColors(globalUser?.colors);

const GlobalStoreUser = createStore({
  initialState: globalUser,
  actions: {
    setUser: (user) => ({ setState }) => {
      console.log("%csetUser", "color:red", user);
      storage.setObject('global-user', user);
      setState(user);
    },
    setCredit: (credit) => ({ setState, getState }) => {
      const user = getState();
      user.credit = credit;
      storage.setObject('global-user', user);
      setState(user);
    },
    setLoyalty: (loyalty) => ({ setState, getState }) => {
      const user = getState();
      user.loyalty = loyalty;
      storage.setObject('global-user', user);
      setState(user);
    },
  },
});

const GlobalStore = createStore({
  initialState: {
    light: globalLight,
    lang: globalLang,
    category: globalCategory,
    search: globalSearch,
    searchType: globalSearchType,
    filter: globalFilter,
    mobile: false,
  },
  actions: {
    setLight: (light) => ({ setState, getState }) => {
      storage.set('global-light', light);
      setState({ light });
    },
    setLang: (lang) => ({ setState }) => {
      storage.set('global-lang', lang);
      setState({ lang });
    },
    setSearch: (search) => ({ setState }) => {
      setState({ search: ""+ search });
    },
    setCategory: (category) => ({ setState }) => {
      setState({ category });
    },
    setSearchType: (searchType) => ({ setState }) => {
      setState({ searchType });
    },
    setFilter: (filter) => ({ setState, getState }) => {
      const objList = Object.keys(filter).length > 0 ? { ...getState().filter, ...filter } : Object.assign({});

      // remove undefined values
      Object.keys(objList).forEach(key => {
        if (objList[key] === undefined) delete objList[key];
      });

      setState({ filter: objList });

      // console.log("FilterSTORE", getState().filter, filter);
    },
    setMobile: (mobile) => ({ setState }) => {
      setState({ mobile });
    },
  },
});

export const useGlobalStore = createHook(GlobalStore);
export const useGlobalStoreUser = createHook(GlobalStoreUser);
