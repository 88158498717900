import styled from 'styled-components';

import IconExit from '../icon/IconExit';
import IconButton from './iconbutton';
import useEscapeKey from './useEscapeKey';

// interface Props {
//   children: React.ReactNode;
//   open: boolean;
//   onClose: Function;
// }

const DivModal = styled.div`
  z-index:1850; /* prevent jqwidgets overlay*/
`;

export default function Dialog(props) {
  const { dialogKey, open, onClose, maxWidth, onTop } = props;
  const bgColor = props?.bgColor || "bg-white";
  const iconColor = props?.iconColor || "text-gray";

  useEscapeKey(onClose);

  if (!open) {
    return <></>;
  }

  return (
    <DivModal className="fixed inset-0 z-50 flex overflow-auto transition bg-gray-800 bg-opacity-50 modal-bg" data-key={dialogKey}>
      <div 
        className={`relative w-full m-auto flex-col flex rounded-lg shadow transition 
          ${bgColor} ${maxWidth || "max-w-md"} ${onTop ? "mt-14" : ""}`}
      >
        <div>{props.children}</div>
        <div className="block absolute top-3 right-3">
          <IconButton onClick={() => onClose()}>
            <IconExit color={iconColor} />
          </IconButton>
        </div>
      </div>
    </DivModal>
  );
}
