import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../router/link';
import { useGlobalStore, useGlobalStoreUser } from '../context/global';

import MobileBtn from './mobilebtn';
import Search from './search';
import SearchButton from './searchbutton';
import BigCart from './bigcart';
import Logout from './logout';

import Guardian from './guardian';
// import ChatSocket from '../chat';
import UserProfile from './UserProfile';
import PatternBuy from './patternbuy';
import AlertMessage from './AlertMessage';
import UserHan from './UserHan';
import UserBranch from './UserBranch';
import UserCredit from './UserCredit';
import UserPoints from './UserPoints';
import TranslateGoogle from '../translate/TranslateGoogle';
import TranslateInternal from '../translate/TranslateInternal';
import WindowZoom from '../WindowZoom';
import LinkDropDown from "../LinkDropDown";

// import useWindowSize from '../hook/useWindowSize';

import useFileCache from '../hook/useFileCache';
import LazyImage from '../hook/lazyimage';

import SmartsUpp from '../SmartsUpp';

export default function Nav() {
  const [globalStoreUser] = useGlobalStoreUser();
  const [globalStore, globalDispatch] = useGlobalStore();
  const { t } = useTranslation('home');

  const [pathName, setPathName] = useState('');
  // const [clientWidth] = useWindowSize();

  const [,,,loadLogoImage] = useFileCache();

  const switchMobile = (on) => {
    globalDispatch.setMobile(on);
  };

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  const searchShow = /\/home|\/article|\/cart/.test(pathName);
  const windowZoom = !!(+document.querySelector('meta[name="portal-windowzoom"]').content);

  return (
    <>
      {windowZoom && <WindowZoom />}
      {globalStoreUser?.smartsupp_enable && <SmartsUpp apiKey={globalStoreUser?.smartsupp_key} />}
      <nav className="bg-gray-100">
        <div className="px-4 mx-auto max-w-8xl sm:px-6">
          <div className="flex items-center justify-between h-8 gap-3 text-xs text-gray-800">
            <div>
              <AlertMessage log={!(globalStore.light == 1)} />
            </div>
            {!!globalStoreUser?.is_han == false && globalStore.light != 1 && (
              <Guardian />
            )}
            {!!globalStoreUser?.trans_enable && globalStoreUser?.trans_enable == 2 && <TranslateGoogle />}
            {!!globalStoreUser?.trans_enable && globalStoreUser?.trans_enable == 3 && <TranslateInternal />}
            {!!globalStoreUser?.is_han && <UserHan id={globalStoreUser?.is_han} />}
            {!!globalStoreUser?.branch_show && <UserBranch />}
            {/*
            <!-- for development -->
            {process.env.NODE_ENV === 'development' && (
              <div className="">
                <ChatSocket />
              </div>
            )}
            */}
            <div className="flex-grow" />
            {!!globalStoreUser?.show_loyalty_points != false && (
              <UserPoints />
            )}
            {!!globalStoreUser?.is_han == false && ( 
              <UserCredit />
            )}
            {/*
            <div className="hidden px-3 lg:block">
              <span className="text-gray-600">Przeterminowane:</span>
              &nbsp;
              <span className="text-red-600">{globalStoreUser.credit_minus || 0} zł</span>
            </div>
            */}
            {typeof globalStoreUser == "object" && (
              <>
                {globalStoreUser.company && (
                  <div className="hidden lg:block">
                    <span translate='no' data-cy="company-name">{globalStoreUser.company}</span>
                  </div>
                )}
                <UserProfile user={globalStoreUser} />
              </>
            )}
            {/* <Logout /> */}
          </div>
        </div>
      </nav>

      <nav className="relative bg-white">
        <span className="shadowround" />

        <div className="relative z-10 px-4 mx-auto bg-white max-w-8xl sm:px-6">
          <div className="flex items-center justify-start h-16">
            <MobileBtn isOpen={false} setIsOpen={switchMobile} />
            <div className="flex items-center">
              <div className="flex-shrink-0 hidden p-4 lg:pl-0 md:flex">
                <Link to="/home" data-cy="company-logo">
                  {typeof globalStoreUser != "object" || ("" + globalStoreUser?.profile) == "" && (
                    <img className="h-8" src="/plugins/logo.png" data-cy="company-logo" alt="Humansoft logo" /> 
                  )}
                  {typeof globalStoreUser == "object" && globalStoreUser?.profile != "" && (
                    <LazyImage
                      className="h-8"
                      src="/plugins/logo.png"
                      symbol={globalStoreUser.profile}
                      width=""
                      height="40"
                      alt="Humansoft logo"
                      handlerLoad={(symbol) => loadLogoImage(symbol)}
                      ignoreLight={1}
                    />
                  )}
                </Link>
              </div>
              <div className={`${typeof globalStoreUser?.modules == 'object' && globalStoreUser?.modules[1] == 1 && globalStoreUser?.modules[0] != 1 ? '' : 'hidden'} md:block`}>
                <div className="flex items-center mx-4 space-x-4">
                  {typeof globalStoreUser?.modules == 'object' && globalStoreUser?.modules[0] == 1 && (
                    <Link to={`/article${globalStore.category.length > 0 ? '#'+globalStore.category : ''}`} className={`${pathName.includes('/article') ? 'active' : ''} px-3 py-2 h-16 flex items-center text-base text-center font-medium hover:text-primary `}>
                    {/* <Link to={`/article`} className={`${pathName.includes('/article') ? 'active' : ''} px-3 py-2 text-base text-center font-medium hover:text-primary `}> */}
                      {/* <span className="text-[#ed19d1]">Zakupy</span> */}
                      <span>{t('Zakupy')}</span>
                    </Link>
                  )}
                  {typeof globalStoreUser?.modules == 'object' && globalStoreUser?.modules[1] == 1 && (
                    <LinkDropDown to="/client" data-cy="client-zone-button" className={`${pathName.includes('/client') ? 'active' : ''} px-3 py-2 h-16 text-base text-center font-medium text-gray-700 hover:text-primary`}>
                      {t('Strefa partnera')}
                    </LinkDropDown>
                  )}
                  {/* <Link to="/claim" className={`${pathName === '/claim' ? 'active' : ''} px-3 py-2 h-16 text-base text-center font-medium text-gray-700 hover:text-primary`}>Reklamacje</Link> */}
                  {/* <Link to="/document" className={`${pathName === '/document' ? 'active' : ''} px-3 py-2 h-16 text-base text-center font-medium text-gray-700 hover:text-primary`}>Dokumenty</Link> */}
                </div>
              </div>
            </div>

            {typeof globalStoreUser?.modules == 'object' && globalStoreUser?.modules[0] == 1 && (
              <>
                {searchShow && (
                  <div className="block">
                    <div className="relative flex items-center">
                      <Search />
                    </div>
                  </div>
                )}

                <div className={`flex justify-center flex-grow`} />

                <div className="block">
                  <div className="relative flex items-center">
                    <PatternBuy />
                  </div>
                </div>

                <div className="block">
                  <div className="relative flex items-center ">
                    <BigCart />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
