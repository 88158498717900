import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import IconSearch from '../icon/IconSearch';
import SearchInput from './searchinput';
import Dialog from '../modal/dialog';
import Portal from '../context/portal';

import { useGlobalStore } from '../context/global';
import { useSearchEvents } from '../hook/useSearchEvents';
import { debounce, throttle } from '../util';
import { gaEvent } from '../GoogleAnalytics';

const searchMode = {
  Normal: 0,
  Simple: 1,
};

const Search = ({ mode = searchMode.Simple, catalog = false }) => {
  const [globalState, globalDispatch] = useGlobalStore();
  const { t } = useTranslation("home");

  const [isOpen, setIsOpen] = useState(false);
  const [documentWidth, setDocumentWidth] = useState(document.body.clientWidth);
  const refSearchButton = useRef(null);
  const macOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const isMobileWidth = 1024;

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onInput = useCallback(
    (event) => {
      setIsOpen(true);
      // setInitialQuery(event.key);
    },
    // [setIsOpen, setInitialQuery]
    [setIsOpen]
  );

  useSearchEvents({
    isOpen,
    onOpen,
    onClose,
    onInput,
    refSearchButton,
  });

  const searchClear = (e) => {
    e && e.stopPropagation();
    globalDispatch.setSearch('');
  };

  const searchAction = () => {
    // globalState.search.length > 0 ? searchClear() : onOpen();
    onOpen();
  };

  const updateDocumentWidth = debounce((width) => {
    setDocumentWidth(width);
  }, 100);

  const onWidthChange = () => {
    updateDocumentWidth(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onWidthChange);
    return () => {
      window.removeEventListener('resize', onWidthChange);
      onClose();
    }
  }, []);

  useEffect(() => {
    if (globalState.search.length <= 0) return false;

    gaEvent('search', { search_term: globalState.search });
  }, [globalState.search]);

  return (
    <div className="relative max-w-400">
      {/* SEARCH BUTTON NEW */}
      <button
        type="submit"
        className={`lg:hidden ${globalState.search.length > 0 ? 'active' : ''} relative flex 
          items-center rounded-md p-2 text-gray-800 hover:text-primary hover:cursor-pointer group`}
        onClick={searchAction}
        data-cy="search-button-new"
        ref={refSearchButton}
      >
        <span className='relative hidden xs:flex flex-col mr-2 -mb-1 text-xs group-hover:text-primary'>
          <span className='border bg-white border-gray-500 rounded-md px-1 '>
            {macOS ? <span className='relative top-px inline-block text-base leading-3'>⌘</span> : 'Ctrl+'}K
          </span>
          <span>{t('szukaj')}</span>
        </span>
        <IconSearch size={8} className='group-hover:text-primary' />
        <p
          className={`${globalState.search.length > 0 ? '' : 'invisible'} relative top-0 right-0 place-self-start`}
          onClickCapture={searchClear}
        >
          <span className="flex justify-center text-center">
            <span 
              className="absolute top-0 -right-3 block rounded-full p-0.5 text-xs font-sans font-bold text-white 
              bg-red-500 hover:bg-black hover:text-white" 
              style={{ minWidth: "17px", height: "17px", paddingTop: "1px"}}
            >
              ✖
            </span>
          </span>
        </p>
      </button>

      <div className='lg:hidden'>
      {documentWidth < isMobileWidth && isOpen && (
        <Portal elementId="search-container">
          <Dialog open onTop={true} onClose={onClose} bgColor="bg-white" iconColor={"text-black"} dialogKey={'search_dialog'}>
            <SearchInput onClose={onClose} isMode={!(documentWidth >= 390)} catalog={catalog} dialog={true} isOpen={isOpen} />
          </Dialog>
        </Portal>
      )}
      </div>
      <div className='hidden lg:block'>
        {documentWidth >= isMobileWidth && (
          <SearchInput onClose={onClose} isMode={!(documentWidth >= 1280)} catalog={catalog} isOpen={isOpen} />
        )}
      </div>
    </div>
  );
};

export default Search;
